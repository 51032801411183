import React from "react"
import styled from "styled-components"

import StandardWrapper from "../../Shared/StandardWrapper"
import ScriptTitle from "../../Shared/ScriptTitle"

const IntroStyled = styled.div`
  position: relative;
  margin-top: -5rem;
  z-index: 25;

  @media (min-width: 768px) {
    margin-top: -2.5rem;
  }

  @media (min-width: 1025px) {
    margin-top: -5vw;
  }

  .introWrapper {
    @media (min-width: 768px) {
    }
    @media (min-width: 1025px) {
      max-width: 65vw;
      margin-left: 10vw;
      padding: 0;
    }
  }

  .intro-content {
    width: 100%;
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
    }
  }
`

const Intro = () => {
  return (
    <IntroStyled>
      <StandardWrapper modifierClass="introWrapper">
        <div className="intro-content">
          <ScriptTitle title="<h2 class='title-script'>Articles, resources, insight and inspiration to make your day the best kind of day.</h2>" />
        </div>
      </StandardWrapper>
    </IntroStyled>
  )
}

export default Intro
