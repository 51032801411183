import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/Seo/seo"
import HeaderScene from "../components/PageParts/Blog/HeaderScene"
import Intro from "../components/PageParts/Blog/Intro"
import AllBlogPosts from "../components/PageParts/Blog/AllBlogPosts"

const BlogPage = props => {
  const {
    posts: { edges: posts },
    morePosts: { edges: morePosts },
  } = props.data

  return (
    <Layout location={props.location.pathname}>
      <SEO
        title="Switchback Creative - Blog - News, Tips & Tricks"
        description="Switchback Creative blog. Read all about print and web design ideas and events happening around Airdrie and Southern Alberta."
        location={props.location.pathname}
        pageImg={props.data.metaImage.publicURL}
      />
      <HeaderScene />
      <Intro />
      <AllBlogPosts posts={posts} morePosts={morePosts} />
    </Layout>
  )
}

export const blogQuery = graphql`
  {
    posts: allWordpressPost(
      sort: { fields: [date], order: [DESC] }
      limit: 12
    ) {
      edges {
        node {
          title
          id
          slug
          excerpt
          date(formatString: "MMMM D, Y")
          categories {
            name
          }
          featured_media {
            source_url
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    morePosts: allWordpressPost(
      sort: { fields: [date], order: [DESC] }
      skip: 12
    ) {
      edges {
        node {
          title
          id
          slug
          excerpt
          date(formatString: "MMMM D, Y")
          categories {
            name
          }
          featured_media {
            source_url
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    metaImage: file(relativePath: { eq: "switchback-newsintel-meta.jpg" }) {
      publicURL
    }
  }
`

export default BlogPage
