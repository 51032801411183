import React from "react"
import styled from "styled-components"

import MainTitle from "../../Shared/MainTitle"
import { fontSizer } from "../../../Utilities/"
import WhiteCloudSvg from "../../../assets/cloud-white.svg"

const HeaderSceneStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 55rem;
  max-height: 55rem;
  background: linear-gradient(
    168deg,
    #fff,
    #fcfdfd 7%,
    #f4f6f7 20%,
    #e6ebee 32%,
    #d2dbe0 42%,
    #b8c7cf 50%,
    #98aeba 60%,
    #7390a0 65%,
    #476e83 85%,
    #174862 90%,
    #003653
  );
  overflow: hidden;

  @media (min-width: 768px) {
    height: 65vh;
    min-height: 65rem;
    max-height: 90rem;
    background: linear-gradient(
      168deg,
      #fff,
      #fcfdfd 24%,
      #f4f6f7 37%,
      #e6ebee 49%,
      #d2dbe0 58%,
      #b8c7cf 67%,
      #98aeba 75%,
      #7390a0 83%,
      #476e83 90%,
      #174862 97%,
      #003653
    );
  }
`

const HeaderTitle = styled.div`
  position: absolute;
  bottom: 27.5rem;
  right: 0;
  left: 0;
  z-index: 100;
  text-align: left;

  @media (min-width: 768px) {
    right: 0;
    bottom: 2vw;
    left: 0;
    width: 100%;
    max-width: 90rem;
    margin: auto;
    padding: 0 2rem;
  }

  @media (min-width: 1025px) {
    right: 10vw;
    bottom: 5vw;
    left: 10vw;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  h2 {
    ${fontSizer(2.2, 5, 76.8, 160, 2.6)}
  }
`

const WhiteCloudWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  bottom: -1rem;
  left: 0rem;
  width: 115vw;
  z-index: 10;

  @media (min-width: 375px) {
    bottom: -1.25rem;
  }

  @media (min-width: 768px) {
    bottom: -10rem;
  }

  @media (min-width: 1025px) {
    bottom: -15rem;
    max-width: 200rem;
  }

  @media (min-width: 1850px) {
    bottom: -30rem;
    max-width: 350rem;
  }

  .cloud-inside {
    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      width: 100%;
      height: 100%;
    }
  }

  svg {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }

  @media (min-width: 768px) {
    bottom: -8.25vw;
  }
`

const HeaderScene = () => {
  return (
    <HeaderSceneStyled>
      <HeaderTitle>
        <MainTitle title="News + Intel" />
      </HeaderTitle>
      <div>
        <WhiteCloudWrapper>
          <div className="cloud-inside">
            <WhiteCloudSvg />
          </div>
        </WhiteCloudWrapper>
      </div>
    </HeaderSceneStyled>
  )
}

export default HeaderScene
