import React, { Component } from "react"
import PostCard from "./PostCard"
import styled from "styled-components"

import { MedWrapper, buttonOne } from "../../../Utilities"

const AllBlogPostsStyled = styled.div`
  .wrapper {
    ${MedWrapper};
    justify-content: flex-start;
  }

  .load-more-posts {
    width: 100%;
    margin: 5rem auto;
    text-align: center;

    button {
      ${buttonOne};
    }
  }
`

class AllBlogPosts extends Component {
  constructor(props) {
    super(props)
    this.loadMorePosts = this.loadMorePosts.bind(this)
    this.state = {
      morePosts: [],
      currentLoadedPosts: [],
      currentSkip: 0,
      skipSetting: 6,
      buttonDisabled: false,
    }
  }

  componentDidMount() {
    this.setState(prevState => {
      return {
        ...prevState,
        morePosts: this.props.morePosts,
      }
    })
  }

  loadMorePosts() {
    if (this.state.currentSkip >= this.state.morePosts.length) {
      this.setState(prevState => {
        return {
          ...prevState,
          buttonDisabled: true,
        }
      })
      return
    }

    const theNextSixPosts = this.state.morePosts.slice(
      this.state.currentSkip,
      this.state.currentSkip + this.state.skipSetting
    )

    this.setState(prevState => {
      return {
        ...prevState,
        currentLoadedPosts: this.state.currentLoadedPosts.concat(
          theNextSixPosts
        ),
        currentSkip: this.state.currentSkip + this.state.skipSetting,
        buttonDisabled:
          this.state.currentSkip + this.state.skipSetting >=
          this.state.morePosts.length,
      }
    })
  }

  render() {
    const { posts } = this.props
    let loaderCounter = 0
    return (
      <AllBlogPostsStyled>
        <div className="wrapper">
          {posts.map((post, index) => {
            return (
              <PostCard key={post.node.id} position={index} post={post.node} />
            )
          })}

          {this.state.currentLoadedPosts.map((post, index) => {
            if (loaderCounter >= 6) {
              loaderCounter = 1
            } else {
              loaderCounter++
            }
            return (
              <PostCard
                key={post.node.id}
                index={index}
                counter={loaderCounter}
                position={11 + this.state.currentSkip}
                post={post.node}
              />
            )
          })}

          <div className="load-more-posts">
            <button
              disabled={this.state.buttonDisabled}
              onClick={this.loadMorePosts}
            >
              {this.state.buttonDisabled ? "No More Posts" : "Load More Posts"}
            </button>
          </div>
        </div>
      </AllBlogPostsStyled>
    )
  }
}

export default AllBlogPosts
