import React from "react"
import { Link } from "gatsby"
import BgImg from "gatsby-background-image"
import styled, { keyframes, css } from "styled-components"

import {
  buttonTwo,
  bodyCopy,
  fontSizer,
  headlineThree,
  colors,
} from "../../../Utilities"

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0rem);
  }
`

const complexMixin = css`
  animation-name: ${fadeIn};
  animation-duration: 1.5s;
  animation-delay: ${props => props.delay};
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  opacity: 0;
`

const PostCardStyled = styled.article`
  position: relative;
  width: 100%;
  margin-bottom: 7.5rem;
  padding-bottom: 5rem;
  ${props => (props.removeAnimation === false ? complexMixin : false)}

  @media (min-width: 768px) {
    width: calc(50% - 4rem);
    padding-bottom: 2.5rem;
    margin: 2rem;
  }

  @media (min-width: 1025px) {
    width: calc(33.3333% - 4rem);
    margin: 2rem;
    padding-bottom: 5rem;
  }

  .post-bg-img {
    width: 100%;
    min-height: 30rem;
  }

  .post-category {
    margin-bottom: 1.5rem;
    p {
      ${bodyCopy};
      ${fontSizer(1.6, 1.8, 76.8, 150, 2)}
      margin: 0;
      color: ${colors.colorSecondary};
      text-transform: uppercase;
    }
  }

  .post-title {
    margin-bottom: 2.5rem;

    h2 {
      ${headlineThree};
      ${fontSizer(2, 3, 76.8, 150, 2.2)}
      margin: 0;
      color: ${colors.colorPrimary};
      text-transform: uppercase;

      a {
        font-size: 1em;
      }
    }
  }

  .post-date {
    p {
      ${bodyCopy};
      ${fontSizer(1.6, 1.8, 76.8, 150, 2)}
      margin: 0;
      margin-bottom: 1rem;
      color: #585858;
      text-transform: uppercase;
    }
  }

  .post-excerpt {
    p {
      ${bodyCopy};
      ${fontSizer(1.6, 1.8, 76.8, 150, 2)}
      color: #585858;
    }
  }

  .post-read-more {
    position: absolute;
    bottom: 0;
    left: 0;

    a {
      ${buttonTwo};
      ${fontSizer(1.8, 2, 76.8, 110, 2)};
    }
  }

  &.big-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    @media (min-width: 768px) {
      padding-bottom: 0;
    }

    @media (min-width: 1025px) {
      margin-bottom: 4rem;
      padding-bottom: 0;
    }

    .post-bg-img {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(50% - 4rem);
        margin-right: 4rem;
      }
    }

    .post-content {
      width: 100%;

      @media (min-width: 768px) {
        position: relative;
        width: calc(50% - 4rem);
        margin-left: 4rem;
        padding-bottom: 5rem;
      }
    }

    .post-read-more {
      bottom: 1rem;
    }
  }
`

const PostCard = ({ post, position, index, counter }) => {
  let cardClass = ""
  if (position < 3) cardClass = "big-card"
  const removeAnimation = index === undefined ? true : false
  const counterTime = counter === undefined ? 0 : counter

  return (
    <PostCardStyled
      className={`${cardClass}`}
      delay={`0.${counterTime}s`}
      removeAnimation={removeAnimation}
    >
      <BgImg
        className="post-bg-img"
        Tag="div"
        fluid={post.featured_media.localFile.childImageSharp.fluid}
      />
      <div className="post-content">
        <div className="post-category">
          <p>{post.categories[0].name}</p>
        </div>
        <div className="post-title">
          <h2>
            <Link
              to={`/blog/${post.slug}`}
              dangerouslySetInnerHTML={{ __html: post.title }}
            />
          </h2>
        </div>
        <div className="post-date">
          <p>{post.date}</p>
        </div>
        <div
          className="post-excerpt"
          dangerouslySetInnerHTML={{ __html: post.excerpt }}
        />
        <div className="post-read-more">
          <Link to={`/blog/${post.slug}`}>Read More</Link>
        </div>
      </div>
    </PostCardStyled>
  )
}

export default PostCard
